<template>
  <v-flex xs12>
    <v-autocomplete
      v-model="selection"
      :disabled="isUpdating"
      :items="options"
      box
      chips
      color="blue-grey lighten-2"
      label="Filter"
      item-text="name"
      return-object
      multiple
      @input="$emit('input', filter)"
    >
      <template
        slot="selection"
        slot-scope="data"
      >
        <v-chip
          :selected="data.selected"
          close
          class="chip--select-multi"
          @input="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template
        slot="item"
        slot-scope="data"
      >
        <template v-if="typeof data.item !== 'object'">
          <v-list-tile-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-tile-content>
            <v-list-tile-title v-html="data.item.name" />
          </v-list-tile-content>
        </template>
      </template>
    </v-autocomplete>
  </v-flex>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      autoUpdate: true,
      selection: [
        // { name: "Enrolled", group: "enrolEnd", value: false },
        { name: 'Grade 12', group: 'grade', value: '12' },
        { name: 'Full Time', group: 'attdType', value: 'FT' },
        { name: 'Male', group: 'gender', value: 'M' },
      ],
      isUpdating: false,
      name: 'student-filter',
      options: [
        { header: 'Erollment' },
        { name: 'Enrolled', group: 'enrolEnd', value: false },
        { name: 'Not Enrolled', group: 'enrolEnd', value: true },
        { divider: true },
        { header: 'Grade' },
        { name: 'Grade 12', group: 'grade', value: '12' },
        { name: 'Grade 11 ', group: 'grade', value: '11' },
        { name: 'Grade 10', group: 'grade', value: '10' },
        { divider: true },
        { header: 'Full/Part Time' },
        { name: 'Full Time', group: 'attdType', value: 'FT' },
        { name: 'Part Time', group: 'attdType', value: 'PT' },
        { divider: true },
        { header: 'Gender' },
        { name: 'Male', group: 'gender', value: 'M' },
        { name: 'Female', group: 'gender', value: 'F' },
      ],
    }
  },

  computed: {
    filter() {
      const items = this.selection
      const filter = {}
      items.reduce((filterItem, { group, value }) => {
        const filterItemClone = filterItem
        if (!filterItemClone[group]) {
          filterItemClone[group] = []
        }
        filterItem[group].push(value)
        return filterItem
      }, filter)

      return filter
    },
  },

  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => { this.isUpdating = false }, 3000)
      }
    },
  },

  created() {
    this.$emit('input', this.filter)
  },

  methods: {
    remove(item) {
      const index = this.selection.indexOf(item.name)
      if (index >= 0) this.selection.splice(index, 1)
    },
  },
}
</script>
