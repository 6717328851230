<template>
  <v-container grid-list-md>
    <v-layout row>
      <v-flex
        xs12
        sm5
        md5
      >
        <student-list @selected="selected = $event" />
      </v-flex>
      <v-flex
        xs12
        sm7
        md7
      >
        <student-card
          v-if="selected"
          :student="selected"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import StudentList from '@/components/student/StudentList.vue'
import StudentCard from '@/components/student/StudentCard.vue'

export default {
  name: 'Student',
  components: {
    'student-list': StudentList,
    'student-card': StudentCard,
  },
  data() {
    return {
      selected: null,
    }
  },
}
</script>
