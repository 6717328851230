<template>
  <v-menu
    bottom
    left
  >
    <v-btn
      slot="activator"
      icon
    >
      <v-icon>more_vert</v-icon>
    </v-btn>

    <v-list>
      <v-list-tile
        v-for="(item, i) in items"
        :key="i"
        @click="$emit('menuOptionClicked', item.to)"
      >
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'StudentCardMoreButton',
  props: ['item'],
  data() {
    return {

    }
  },
  computed: {
    items() {
      return [
        {
          title: 'Account',
          to: `/student-account/${this.item.id}`,
        },
        {
          title: 'Courses',
          to: `/student/${this.item.id}/courses`,
        },
      ]
    },
  },
}
</script>
