<template>
  <v-card>
    <v-img
      :src="`http://teacademy.ca:8080/api/image/student/${ item.id }`"
      aspect-ratio="2"
      height="300px"
    >
      <v-layout
        column
        fill-height
      >
        <v-card-title>
          <v-btn
            dark
            icon
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>

          <v-spacer />

          <v-btn
            dark
            icon
            class="mr-3"
          >
            <v-icon>edit</v-icon>
          </v-btn>

          <card-menu-button
            :item="item"
            @menuOptionClicked="$router.push($event)"
          />
        </v-card-title>

        <v-spacer />

        <v-card-title class="white--text pl-5 pt-5">
          <div class="display-1 pl-5 pt-5">
            {{ `${ capFirst(item.firstName) } ${ capFirst(item.lastName) }` }}
          </div>
        </v-card-title>
      </v-layout>
    </v-img>

    <v-list two-line>
      <v-list-tile @click="popEdit('Mobile', item.homePhone)">
        <v-list-tile-action>
          <v-icon color="indigo">
            phone
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.homePhone }}</v-list-tile-title>
          <v-list-tile-sub-title>Mobile</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-icon>chat</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-list-tile @click="popEdit('Mobile', item.homePhone)">
        <v-list-tile-action />

        <v-list-tile-content>
          <v-list-tile-title>{{ item.officePhone }}</v-list-tile-title>
          <v-list-tile-sub-title>Work</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action>
          <v-icon>chat</v-icon>
        </v-list-tile-action>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile @click="popEdit('Email', item.email)">
        <v-list-tile-action>
          <v-icon color="indigo">
            mail
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.email }}</v-list-tile-title>
          <v-list-tile-sub-title>Personal</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile @click="popEdit('Address', item.address)">
        <v-list-tile-action>
          <v-icon color="indigo">
            location_on
          </v-icon>
        </v-list-tile-action>

        <v-list-tile-content>
          <v-list-tile-title>{{ item.address }}</v-list-tile-title>
          <v-list-tile-sub-title>
            {{ item.province }}, {{ item.postalCode }}
          </v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-divider inset />

      <v-list-tile @click="popEdit('Mobile', item.homePhone)">
        <v-list-tile-action />

        <v-list-tile-content>
          <v-list-tile-title>
            <v-select
              v-model="selectedTypeID"
              :items="studentTypes"
              item-text="type"
              item-value="typeID"
              solo
            />
            <v-spacer />
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-card>
</template>

<script>
import StudentCardMoreButton from '@/components/student/StudentCardMoreButton.vue'

export default {
  name: 'StudentCard',
  components: {
    'card-menu-button': StudentCardMoreButton,
  },
  props: ['student'],
  data() {
    return {
      studentTypes: [],
      selectedTypeID: null,
    }
  },
  computed: {
    item() {
      return this.student || {}
    },
    // studentType() {
    //   return this.studentTypes.find(type => type.typeID === this.student.studentType) || {}
    // },
  },
  watch: {
    item(value) {
      this.selectedTypeID = value.studentType
    },
    selectedTypeID(value) {
      
    },
  },
  created() {
    // this.$store.dispatch('studentTypes/all')
    //   .then((data) => { this.studentTypes = data })
  },
  methods: {
    capFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    handleError(e) {
      const event = e
      event.target.src =
        'http://icons.iconarchive.com/icons/papirus-team/papirus-status/256/avatar-default-icon.png'
    },
  },
}
</script>
