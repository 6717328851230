<template>
  <v-card>
    <v-toolbar
      color="indigo"
      dark
    >
      <!-- <v-toolbar-side-icon></v-toolbar-side-icon> -->

      <v-toolbar-title>Students</v-toolbar-title>

      <v-spacer />
      <v-text-field
        v-model="search"
        label="Search"
        solo
      />
      <v-btn icon>
        <v-icon>search</v-icon>
      </v-btn>

      <v-menu
        bottom
        left
        offset-y
        origin="center center"
        :close-on-content-click="false"
        transition="scale-transition"
      >
        <v-btn
          slot="activator"
          icon
        >
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-card>
          <student-filter-box v-model="filter" />
        </v-card>
      </v-menu>
    </v-toolbar>

    <v-list two-line>
      <template v-for="(item, index) in searchItems">
        <v-list-tile
          :key="item.id"
          :style="isSelected(item) ? 'background-color: #DCEDC8' : ''"
          avatar
          ripple
          @click="select(item)"
        >
          <v-list-tile-avatar>
            <!-- <img :src="`http://teacademy.ca:8080/imagetoBrowse?id=${ item.id }`" @error="handleError($event)"> -->
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ `${printName(item.firstName, item.lastName, item.perferName)}` }}
            </v-list-tile-title>
            <v-list-tile-sub-title class="text--primary">
              {{ `Grade ${item.grade}, ${item.attdType}, ${item.gender}, ${item.dateOfBirth}` }}
            </v-list-tile-sub-title>
            <v-list-tile-sub-title>{{ item.subtitle }}</v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-list-tile-action-text>{{ item.id }}</v-list-tile-action-text>
          </v-list-tile-action>
        </v-list-tile>
        <v-divider
          v-if="index + 1 < items.length"
          :key="index"
        />
      </template>
    </v-list>
  </v-card>
</template>

<script>
import StudentFilterBox from '@/components/student/StudentFilterBox.vue'

export default {
  name: 'StudentList',
  components: {
    'student-filter-box': StudentFilterBox,
  },

  data() {
    return {
      selected: {},
      items: [],
      filter: {},
      search: '',
    }
  },

  computed: {
    searchItems() {
      if (!this.search) return this.items

      return this.items.filter((item) => {
        const {
          id, firstName, lastName, perferName, dateOfBirth,
        } = item
        const reduced = {
          id, firstName, lastName, perferName, dateOfBirth,
        }
        return Object.values(reduced).some(value => value.includes(this.search))
      })
    },
  },

  watch: {
    filter(val) {
      this.$store.dispatch('students/all', val)
        .then((data) => {
          // assign first element of data array to selected using destructing
          [this.selected] = data
          this.items = data
          this.$emit('selected', this.selected)
        })
    },
  },

  created() {
    this.init()
  },

  methods: {
    init() {
      this.$store.dispatch('students/all', this.filter)
        .then((data) => {
          // assign first element of data array to selected using destructing
          [this.selected] = data
          this.items = data
          this.$emit('selected', this.selected)
        })
    },
    select(item) {
      this.selected = item
      this.$emit('selected', item)
    },
    printName(first, last, prefer) {
      return `${this.capFirst(first)} ${this.capFirst(last)}(${this.capFirst(prefer)})`
    },
    capFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    handleError(e) {
      const event = e
      event.target.src = 'http://icons.iconarchive.com/icons/papirus-team/papirus-status/256/avatar-default-icon.png'
    },
    isSelected(item) {
      return item === this.selected
    },
  },
}
</script>
